.title {
    font-size: 80% !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .card {
    position: relative;
    overflow: hidden;
    border-radius: 10%;
    width: 100%;
  
    img {
      display: block;
      width: 100%;
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.7);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 300ms;
      pointer-events: none;
    }

    .selected {
      @extend .overlay;
      opacity: 1;
    }
  
    &:hover .overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }
  