.root {
  min-height: 105px;
  flex-direction: row;
  display: flex !important;
  align-items: center;
  padding-right: 3rem;
  margin-left: 40px;
}

.title {
  word-break: break-word;
}
