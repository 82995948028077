.styles_root__1Vezu{color:inherit;text-decoration:inherit}
.styles_logoContainer__3mGm-{padding:10px 0 10px 38px;display:flex;flex-direction:row;align-items:center;width:430px}.styles_logoContainer__3mGm- img{width:60px;height:60px;margin-left:4px;margin-top:-7px}
.styles_root__35PBR{min-height:105px;flex-direction:row;display:flex !important;align-items:center;padding-right:3rem;margin-left:40px}.styles_title__G-oZ0{word-break:break-word}
.styles_content__3SeTN{min-height:calc(100vh - 105px)}
.styles_main__Kkim1{display:flex !important;align-items:center}.styles_buttonPrimaryGlow__1SK7g{box-shadow:0 0 32px #ff0073 !important}
.styles_root__3s3bH{border:2px solid #fff !important;width:60px;min-width:60px !important;height:60px;background-color:rgba(255,255,255,.4) !important;border-radius:8px !important;position:relative;display:flex;justify-content:center;align-items:center;padding:0 !important;margin-bottom:11px !important}.styles_root__3s3bH img{max-width:100%}.styles_icon__3fGav{position:absolute}.styles_counter__JDRPp{position:absolute;bottom:-1px;left:4px;text-transform:none}
.styles_root__1Frlf{position:fixed;right:60px;bottom:50px}@media screen and (max-width: 1200px){.styles_root__1Frlf{right:10px}}
.styles_content__2qIfc{min-height:calc(100vh - 105px);margin-bottom:60px}
.styles_root__1tDrQ{list-style:none;padding-left:0}.styles_root__1tDrQ img{max-width:100%}
.styles_title__2iO-x{font-size:1rem !important}.styles_card__2Hnry{position:relative;overflow:hidden;border-radius:35px}.styles_card__2Hnry img,.styles_card__2Hnry video{display:block;width:100%}.styles_card__2Hnry .styles_overlay__1fqpC{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.7);display:flex;justify-content:center;align-items:center;opacity:0;transition:opacity 300ms;pointer-events:none}.styles_card__2Hnry:hover .styles_overlay__1fqpC{opacity:1;pointer-events:auto}
.styles_key__1Tf-z{padding-right:1rem;text-transform:capitalize;margin-bottom:8px}
.styles_label__2v6BG{color:#ff0073 !important}
.styles_label__3E9DK{padding-right:1rem;text-transform:capitalize;color:#ff0073 !important}.styles_title__MX6Fd{font-size:100%;height:15%;text-align:center}.styles_verticalLine__2rrQy{border-left:4px solid #fff;height:90%;position:absolute;left:50%;margin-left:-3px;top:0}
.styles_root__2c6bt{list-style:none;padding-left:0}.styles_root__2c6bt img{max-width:100%}
.styles_title__8nUaJ{font-size:80% !important;overflow:hidden;text-overflow:ellipsis}.styles_card__2Bf2M{position:relative;overflow:hidden;border-radius:10%;width:100%}.styles_card__2Bf2M img{display:block;width:100%}.styles_card__2Bf2M .styles_overlay__4XjSk,.styles_card__2Bf2M .styles_selected__34X6T{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.7);display:flex;justify-content:center;align-items:center;opacity:0;transition:opacity 300ms;pointer-events:none}.styles_card__2Bf2M .styles_selected__34X6T{opacity:1}.styles_card__2Bf2M:hover .styles_overlay__4XjSk,.styles_card__2Bf2M:hover .styles_selected__34X6T{opacity:1;pointer-events:auto}
.styles_title__CrW6k{font-size:80% !important;text-align:center}.styles_card__2mL2V{position:relative;overflow:hidden;background:rgba(255,255,255,.2);border-radius:10%;width:105px !important;height:105px !important}.styles_card__2mL2V img{display:block;width:30%;margin:0 auto;height:30%}.styles_card__2mL2V .styles_overlay__3yXJQ,.styles_card__2mL2V .styles_selected__1c3ap{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.7);display:flex;justify-content:center;align-items:center;opacity:0;transition:opacity 300ms;pointer-events:none}.styles_card__2mL2V .styles_selected__1c3ap{opacity:1}.styles_card__2mL2V:hover .styles_overlay__3yXJQ,.styles_card__2mL2V:hover .styles_selected__1c3ap{opacity:1;pointer-events:auto}
.styles_root__1j7Kq{list-style:none;padding-left:0}.styles_root__1j7Kq img{max-width:100%}
.styles_label__J_q0Q{padding-right:1rem;text-transform:capitalize;color:#ff0073 !important}.styles_title__4f_aA{font-size:100%;height:15%;text-align:center}
.styles_root__24Uzu{width:100%;border-radius:8px;overflow:hidden}.styles_xl__17HEE{border-radius:8%}
.styles_label__2bj3x{padding-right:1rem;text-transform:capitalize;color:#ff0073 !important}.styles_title__3sv-1{font-size:100%;height:15%;text-align:center}.styles_ripple__2Qp3m{height:100px}
@font-face {
  font-family: "04b";
  src: url(/static/media/04b30.4d3af809.woff2) format("woff2"),
    url(/static/media/04b30.7564d321.woff) format("woff"),
    url(/static/media/04b30.cb829af0.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Press Start 2P";
  src: url(/static/media/PressStart2P-Regular.320762c3.woff2) format("woff2"),
    url(/static/media/PressStart2P-Regular.5479bce9.woff) format("woff"),
    url(/static/media/PressStart2P-Regular.5f45b521.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  min-height: 100vh;
  min-width: 320px;
  background: url(/static/media/background.1957bdce.gif) center / cover;
}

.text-uppercase {
  text-transform: uppercase;
}

