@import "../../../theme/theme";

.label {
  padding-right: 1rem;
  text-transform: capitalize;
  color: $color_primary !important;
}

.title {
  font-size: 100%;
  height: 15%;
  text-align: center;
}

.ripple {
  height: 100px;
}
