@font-face {
  font-family: "04b";
  src: url("../assets/fonts/04b30.woff2") format("woff2"),
    url("../assets/fonts/04b30.woff") format("woff"),
    url("../assets/fonts/04b30.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Press Start 2P";
  src: url("../assets/fonts/PressStart2P-Regular.woff2") format("woff2"),
    url("../assets/fonts/PressStart2P-Regular.woff") format("woff"),
    url("../assets/fonts/PressStart2P-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  min-height: 100vh;
  min-width: 320px;
  background: url("../assets/images/background.gif") center / cover;
}

.text-uppercase {
  text-transform: uppercase;
}
