.title {
  font-size: 80% !important;
  text-align: center;
}

.card {
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10%;
  width: 105px !important;
  height: 105px !important;

  img {
    display: block;
    width: 30%;
    margin: 0 auto;
    height: 30%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 300ms;
    pointer-events: none;
  }

  .selected {
    @extend .overlay;
    opacity: 1;
  }

  &:hover .overlay {
    opacity: 1;
    pointer-events: auto;
  }
}
