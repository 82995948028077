@import '../../../theme/theme';

.label {
  padding-right: 1rem;
  text-transform: capitalize;
  color: $color_primary !important;
}

.title {
  font-size: 100%;
  height: 15%;
  text-align: center;
}

.verticalLine {
  border-left: 4px solid white;
  height: 90%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}
