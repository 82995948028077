.logoContainer {
  padding: 10px 0 10px 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 430px;

  img {
    width: 60px;
    height: 60px;
    margin-left: 4px;
    margin-top: -7px;
  }
}
