.root {
  border: 2px solid #fff !important;
  width: 60px;
  min-width: 60px !important;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-radius: 8px !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin-bottom: 11px !important;

  img {
    max-width: 100%;
  }
}

.icon {
  //transform: rotate(-30deg);
  position: absolute;

}

.counter {
  position: absolute;
  bottom: -1px;
  left: 4px;
  text-transform: none;
}
