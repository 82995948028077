.title {
  font-size: 1rem !important;
}

.card {
  position: relative;
  overflow: hidden;
  border-radius: 35px;

  img,
  video {
    display: block;
    width: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 300ms;
    pointer-events: none;
  }

  &:hover .overlay {
    opacity: 1;
    pointer-events: auto;
  }
}
