@import "../../theme/theme";

.main {
  display: flex !important;
  align-items: center;
}

.buttonPrimaryGlow {
  box-shadow: 0 0 32px $color_primary !important;
}
